<template>
  <div class="view-container">
    <div class="references-form display--flex">
    <div class="card w-full">
      <div class="card-header card-header-divider">
        <div class="card-title">Эталонные профили</div>
        <div class="tools">
          <button @click="readOnly = false; showPopup = true" class="btn btn-accent">Добавить</button>
        </div>
      </div>
      <div class="card-body">
        <references-interface @openReference="openReference"/>
      </div>
    </div>
  </div>
  </div>
  <reference-popup :profileId="currentId" v-if="showPopup" @close="closePopup" :readOnly="readOnly"/>
</template>

<script>
import { ref } from '@vue/reactivity'
import ReferencesInterface from '../../components/Forms/Analytics/ReferencesInterface.vue'
import ReferencePopup from '../../components/Popups/ReferencePopup.vue'
export default {
  components: { ReferencesInterface, ReferencePopup },
    name: "reference-profiles-page",
    setup() {
        const showPopup = ref(false), currentId = ref(0), readOnly = ref(false)

        const openReference = (data) => {
            currentId.value = data.id
            showPopup.value = true
            readOnly.value = data.readOnly
        }
        const closePopup = () => {
            currentId.value = 0
            showPopup.value = false
        }

        return {
            showPopup, currentId, openReference, closePopup, readOnly
        }
    }
}
</script>

<style>

</style>